import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { ContentWrapperComponent } from '../../components/content-wrapper/content-wrapper.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { Rack } from '../../models/rack';
import { MatButtonModule } from '@angular/material/button';
import { ApiService } from '../../services/api.service';
import { MiscService } from '../../services/misc.service';
import { RackComponent } from './rack/rack.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import {
  ReactiveFormsModule,
  Validators,
  FormBuilder,
  FormsModule,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { Location } from '../../models/location';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@Component({
  selector: 'app-racks',
  standalone: true,
  imports: [
    CommonModule,
    MatSortModule,
    ContentWrapperComponent,
    MatTableModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatTooltipModule,
    TranslateModule,
    MatIconModule,
    MatPaginatorModule,
  ],
  templateUrl: './racks.component.html',
  styleUrl: './racks.component.scss',
})
export class RacksComponent {
  @Input('racks') set racks(value: Rack[] | undefined) {
    console.log('Value: ', value);
    this.dataSource = new MatTableDataSource(value);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  @Input() location?: Location;
  @Input() origin: 'templates' | 'locations' = 'templates';
  @Output() onSelectRack = new EventEmitter<Rack>();
  @Output() onUpdateRack = new EventEmitter<void>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  activatedRackId?: number;
  displayedColumns: string[] = ['id', 'name'];
  activatedRow: any;
  dataSource: MatTableDataSource<Rack> = new MatTableDataSource();

  constructor(
    private translate: TranslateService,
    private api: ApiService,
    private misc: MiscService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.origin == 'templates') {
      console.log('Wrong way!');
      this.api.RACKS_GetRackTemplates().subscribe({
        next: (res) => {
          if (res.ok && res.body) {
            this.dataSource = new MatTableDataSource(res.body);

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: () => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
    } else {
      this.displayedColumns.push('serialName');
      this.displayedColumns.push('actions');
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  addRack(row?: Rack) {
    const _dialogRef = this.dialog.open<RackComponent | AddRackDialog>(
      this.origin == 'templates' ? RackComponent : AddRackDialog,
      { data: { location: this.location }, maxHeight: '80vh' }
    );
    _dialogRef.afterClosed().subscribe((res: { reload: boolean }) => {
      if (res?.reload) {
        if (this.origin == 'templates') {
          this.ngOnInit();
        } else {
          this.onUpdateRack.emit();
        }
      }
    });
  }

  selectRow(row: Rack) {
    this.onSelectRack.emit(row);
    if (this.origin == 'templates') {
      const _dialogRef = this.dialog.open(RackComponent, {
        data: {
          rackId: row.id,
        },
        maxHeight: '80vh',
      });
      _dialogRef.afterClosed().subscribe((res: { reload: boolean }) => {
        if (res?.reload) {
          if (this.origin == 'templates') {
            this.ngOnInit();
          } else {
            this.onUpdateRack.emit();
          }
        }
      });
    }
  }

  editRack(rack: Rack) {
    const _dialogRef = this.dialog.open(AddRackDialog, {
      data: { location: this.location, rack },
      maxHeight: '80vh',
    });
    _dialogRef.afterClosed().subscribe((res: { reload: boolean }) => {
      if (res?.reload) {
        if (this.origin == 'templates') {
          this.ngOnInit();
        } else {
          this.onUpdateRack.emit();
        }
      }
    });
  }
  deleteRack(rack: Rack) {
    if (!rack) return;

    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteRack'),
          message: this.translate.instant('form.deleteRackInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          if (this.origin == 'locations' && this.location) {
            this.api.LOCATIONS_DeleteRack(this.location.id, rack.id).subscribe({
              next: (res) => {
                if (res.ok) {
                  this.onUpdateRack.emit();
                  this.misc.showInfo(
                    this.translate.instant('success.deleteRack', {
                      rack: rack.name,
                    })
                  );
                } else {
                  this.misc.showError(this.translate.instant('error.general'));
                }
              },
              error: (err) => {
                this.misc.showError(this.translate.instant('error.general'));
              },
            });
          } else {
          }
        }
      });
  }
}

@Component({
  selector: 'add-rack-dialog',
  templateUrl: 'racks-add-rack-component.html',
  styleUrl: './racks.component.scss',
  standalone: true,
  imports: [
    ContentWrapperComponent,
    TranslateModule,
    MatFormFieldModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    AsyncPipe,
  ],
})
export class AddRackDialog {
  filteredRacks: Rack[] = [];
  racks: Rack[] = [];
  addRackForm = this.fb.group({
    id: [0],
    rackCode: ['', Validators.required],
    serialName: ['', Validators.required],
  });

  constructor(
    public dialogRef: MatDialogRef<AddRackDialog>,
    public fb: FormBuilder,
    private api: ApiService,
    private misc: MiscService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { location: Location; rack: Rack }
  ) {}

  ngOnInit(): void {
    this.api.RACKS_GetRackTemplates().subscribe({
      next: (res) => {
        if (res.ok && res.body) {
          this.racks = res.body;
          this.filteredRacks = res.body;
          this.addRackForm.patchValue(this.data.rack);

          this.rackName = (rackCode: string) => {
            return (
              this.filteredRacks?.find((e) => e.rackCode == rackCode)?.name ??
              ''
            );
          };
        } else {
          this.misc.showError(this.translate.instant('error.general'));
        }
      },
      error: () => {
        this.misc.showError(this.translate.instant('error.general'));
      },
    });
  }

  rackName(rackCode: string) {
    return this.filteredRacks?.find((e) => e.rackCode == rackCode)?.name ?? '';
  }

  filterRacks(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.filteredRacks = this.racks.filter((rack) =>
      rack.name.toLowerCase().includes(filterValue)
    );
  }

  save() {
    if (this.data.rack) {
      this.addRackForm.get('id')?.patchValue(this.data.rack.id);
      this.api
        .LOCATIONS_PatchRack(
          this.data.location.id,
          this.addRackForm.value as Rack
        )
        .subscribe({
          next: (res) => {
            if (res.ok) {
              this.dialogRef.close({ reload: true });
              this.misc.showInfo(
                this.translate.instant('success.save', {
                  rack: this.racks.find(
                    (e) => e.id === this.addRackForm.get('id')?.value
                  )?.name,
                })
              );
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: () => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    } else {
      const _rackCode = this.addRackForm.get('rackCode')?.value;
      this.addRackForm
        .get('id')
        ?.patchValue(this.racks.find((f) => f.rackCode === _rackCode)?.id ?? 0);
      this.api
        .LOCATIONS_PostRack(
          this.data.location.id,
          this.addRackForm.value as Rack
        )
        .subscribe({
          next: (res) => {
            if (res.ok) {
              this.dialogRef.close({ reload: true });
              this.misc.showInfo(
                this.translate.instant('success.addRack', {
                  rack: this.racks.find(
                    (e) => e.id === this.addRackForm.get('id')?.value
                  )?.name,
                })
              );
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: () => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
    }
  }
}
