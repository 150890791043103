import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from '@angular/material/dialog';
import { ContentWrapperComponent } from '../../../components/content-wrapper/content-wrapper.component';
import { ApiService } from '../../../services/api.service';
import { ProductsComponent } from '../../products/products.component';
import { Rack } from '../../../models/rack';
import { MiscService } from '../../../services/misc.service';
import { Location } from '../../../models/location';
import { ConfirmComponent } from '../../../components/confirm/confirm.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddDocumentDialog } from '../../products/product/product.component';
import { AADocument } from '../../../models/document';

@Component({
  selector: 'app-rack',
  standalone: true,
  imports: [
    CommonModule,
    ContentWrapperComponent,
    ProductsComponent,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
  ],
  templateUrl: './rack.component.html',
  styleUrl: './rack.component.scss',
})
export class RackComponent {
  isBusy = true;
  rack?: Rack;
  displayedColumns: string[] = ['name', 'typ', 'actions'];
  activatedRow: any;

  rackForm = this.fb.group({
    id: 0,
    rackCode: ['', [Validators.required, Validators.maxLength(50)]],
    serialName: ['', Validators.maxLength(50)],
    name: ['', [Validators.required, Validators.maxLength(50)]],
    products: this.fb.array([]),
  });

  constructor(
    public dialogRef: MatDialogRef<RackComponent>,
    private api: ApiService,
    private fb: FormBuilder,
    private misc: MiscService,
    public dialog: MatDialog,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: { rackId?: number; location?: Location }
  ) {}

  ngOnInit() {
    if (this.data?.rackId) {
      this.api.RACKS_GetRackTemplate(Number(this.data.rackId)).subscribe({
        next: (res) => {
          if (res.ok && res.body) {
            this.rackForm.patchValue(res.body);
            this.rack = res.body;
            this.isBusy = false;
          }
        },
      });
    } else {
      this.isBusy = false;
    }
  }

  save() {
    if (this.rack?.id) {
      this.api.RACKS_PutRackTemplate(this.rackForm.value as Rack).subscribe({
        next: (res) => {
          if (res.ok) {
            this.dialogRef.close({ reload: true });
            this.misc.showInfo(this.translate.instant('success.save'));
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: () => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
    } else {
      this.api.RACKS_PostRackTemplate(this.rackForm.value as Rack).subscribe({
        next: (res) => {
          if (res.ok) {
            this.dialogRef.close({ reload: true });
            this.misc.showInfo(
              this.translate.instant('success.createRack', {
                rack: this.rackForm.get('name')?.value,
              })
            );
          } else {
            this.misc.showError(this.translate.instant('error.general'));
          }
        },
        error: () => {
          this.misc.showError(this.translate.instant('error.general'));
        },
      });
    }
  }

  deleteRack() {
    if (!this.rack) return;
    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteRack'),
          message: this.translate.instant('form.deleteRackInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (accepted) {
          this.api.RACKS_DeleteRackTemplate(this.rack!.id).subscribe({
            next: (res) => {
              if (res.ok) {
                this.dialogRef.close({ reload: true });
                this.misc.showInfo(
                  this.translate.instant('success.deleteRack', {
                    rack: this.rack?.name,
                  })
                );
              } else {
                this.misc.showError(this.translate.instant('error.general'));
              }
            },
            error: () => {
              this.misc.showError(this.translate.instant('error.general'));
            },
          });
        }
      });
  }

  viewDocument(data: AADocument) {
    if (!data.url) return;
    window.open(data.url, '_blank');
  }

  deleteDocument(data: AADocument) {
    if (!data.id) return;

    this.dialog
      .open(ConfirmComponent, {
        data: {
          title: this.translate.instant('form.deleteDocument'),
          message: this.translate.instant('form.deleteDocumentInfo'),
        },
      })
      .afterClosed()
      .subscribe((accepted) => {
        if (!accepted) return;
        this.api.DOCUMENTS_DeleteDocument(data.id!).subscribe({
          next: (res) => {
            if (res.ok) {
              this.misc.showInfo(
                this.translate.instant('success.deleteDocument', {
                  document: data.name,
                })
              );
              this.ngOnInit();
            } else {
              this.misc.showError(this.translate.instant('error.general'));
            }
          },
          error: (err) => {
            this.misc.showError(this.translate.instant('error.general'));
          },
        });
      });
  }

  openAddDocumentDialog() {
    const dialogRef = this.dialog.open(AddDocumentDialog, {
      data: {
        rackId: this.data.rackId,
      },
      maxHeight: '80vh',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.reload) {
        this.ngOnInit();
      }
    });
  }
}
