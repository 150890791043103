<app-content-wrapper [title]="data.rack ? ('title.rack' | translate: { rack: data.rack.name}) : 'title.addRack' | translate">
    <button mat-icon-button toolbar (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>
    <form class="aa-form" content [formGroup]="addRackForm">

        @if (data?.location == null || data.rack == null) {
        <p>
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'title.racks' | translate}}</mat-label>
                <input type="text" formControlName="rackCode" matInput [matAutocomplete]="auto" (input)="filterRacks($event)">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="rackName" requireSelection>
                    @for (rack of filteredRacks; track rack) {
                    <mat-option [value]="rack.rackCode">{{rack.name}}</mat-option>
                    }
                </mat-autocomplete>
            </mat-form-field>
        </p>
        }

        <p>
            <!-- <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'title.racks' | translate}}</mat-label>
                <mat-select formControlName="rackCode">
                    @for (item of racks; track item.id) {
                    <mat-option [value]="item.rackCode">{{item.name}}</mat-option>
                    }
                </mat-select>
            </mat-form-field> -->
            <mat-form-field floatLabel="always" appearance="outline">
                <mat-label>{{'form.tagNumber' | translate}}</mat-label>
                <input matInput formControlName="serialName">
            </mat-form-field>
        </p>
        <button mat-raised-button color="primary" [disabled]="addRackForm.invalid" (click)="save()">
            <mat-icon>save</mat-icon>
            {{'form.save' | translate}}
        </button>
    </form>
</app-content-wrapper>
