<app-content-wrapper [title]="'title.racks' | translate" style="flex:1" [headerColor]="origin == 'locations' ? '#444444' : ''"
    [padding]="origin == 'templates'">
    <button mat-icon-button toolbar (click)="addRack()"><mat-icon>add</mat-icon></button>

    <div content>
        <mat-form-field appearance="outline" *ngIf="origin == 'templates'">
            <mat-label>Filter...</mat-label>
            <input matInput (keyup)="applyFilter($event)">
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" class="example-table" matSort matSortDisableClear matSortDirection="desc">

            <ng-container matColumnDef="id">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.rackId' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.rackCode}}</td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.name' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.name}} </td>
            </ng-container>

            <ng-container matColumnDef="serialName" *ngIf="origin == 'locations'">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'form.tagNumber' | translate}}</th>
                <td mat-cell *matCellDef="let row">{{row.serialName}} </td>
            </ng-container>

            <ng-container matColumnDef="actions" *ngIf="origin == 'locations'">
                <th mat-header-cell *matHeaderCellDef>{{'form.actions' | translate}}</th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="editRack(row)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteRack(row)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="table-header"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let odd = odd;" (click)="selectRow(row); activatedRow = row"
                [ngClass]="[odd ? 'odd-row' : '', activatedRow == row ? 'activated-row' : '']"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [attr.colspan]="displayedColumns.length">
                    <div class="table-empty-row">
                        {{'form.emptyData' | translate}}
                    </div>
                </td>
            </tr>
        </table>
        <mat-paginator [pageSize]="origin != 'locations' ? 10 : 5" [hidePageSize]="true"></mat-paginator>
    </div>
</app-content-wrapper>
